import $ from 'jquery'

// NOTE: Our reliance on jquery is huge.
// This is to ease interacting with jquery events from modern JS
// Before importing this, make sure this won't cause any unintended side effects
// Originally taken from - https://discuss.hotwired.dev/t/listening-to-jquery-events-on-stimulus-actions-like-ajax-success/573
// Converted to normal js from coffescript

// ~ Dispatch jQuery events as regular DOM events ~
//
// Delegated events are given a new name in the format `jquery:<original event name>`.
// If you delegate `ajax:send` you will be able to listen for `jquery:ajax:send`
// on native event listeners such as Stimulus actions and `EventTarget.addEventListener`.
//
// Notes:
//   * The first parameter must be called "event".
//   * The parameters can be accessed as members on the `event.detail` object.
//
// Example:
//   delegate 'ajax:send', parameters: ['event', 'xhr']
//   document.addEventListener 'jquery:ajax:send', (event) -> console.log(event.detail)
//
const delegate = function (eventName, { parameters }) {
  const handler = function (...args) {
    const data = {}
    for (let index = 0; index < parameters.length; index++) {
      const name = parameters[index]
      data[name] = args[index]
    }
    const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
      bubbles: true,
      cancelable: true,
      detail: data,
    })
    return data.event.target.dispatchEvent(delegatedEvent)
  }
  return $(document).on(eventName, handler)
}

// jQuery UJS
delegate('ajax:before', { parameters: ['event'] })
delegate('ajax:beforeSend', { parameters: ['event', 'xhr', 'settings'] })
delegate('ajax:send', { parameters: ['event', 'xhr'] })
delegate('ajax:success', { parameters: ['event', 'data', 'status', 'xhr'] })
delegate('ajax:error', { parameters: ['event', 'xhr', 'status', 'error'] })
delegate('ajax:complete', { parameters: ['event', 'xhr', 'status'] })
delegate('ajax:aborted:required', { parameters: ['event', 'elements'] })
delegate('ajax:aborted:file', { parameters: ['event', 'elements'] })
